import internetSpeedEnum from '@/enums/internetSpeedEnum'

export default {
  computed: {
    speedUnit() {
      const plan = this.plan || this.customer?.customer_plan?.internet_plan || this.customerPlan?.internet_plan

      switch (plan?.download_speed_unit) {
        case internetSpeedEnum.KBPS:
          return 'Kbps'
        case internetSpeedEnum.MBPS:
          return 'Mbps'
        case internetSpeedEnum.GBPS:
          return 'Gbps'
        default:
          return ''
      }
    },
  },
}
